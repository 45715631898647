$colMargin: 8px;
$headerHeight: 64px;

$minHeight100: calc(100vh - #{$headerHeight});

//base colors
$white: #ffffff;
$black: #474f5c;

$gray25: #f4f4f4;
$gray50: #f9f9fb;
$gray100: #f7f8fa;
$gray125: #e0e0e0;
$gray150: #eaecf2;
$gray200: #e3e6ed;
$gray250: #dbdee5;
$gray300: #b5bdcd;
$gray350: #a3adc1;
$gray600: #667386;
$gray700: #9e9e9e;

$grayBlue50: #e8edf8;
$grayBlue100: #d5def3;

$blue100: #e0f7ff;
$blue300: #5cd6ff;
$blue400: #3f96ff;
$blue500: #2076ff;
$blue600: #607d8b;

$red100: #ffd0cc;
$red500: #ff1744;

$yellow300: #fff4d9;
$yellow500: #ffb802;

$green100: #dff7ea;
$green500: #2dca73;

//gradient
$redGradient: linear-gradient(225deg, #ff3d6c 0%, #ff5a59 60%, #ff8b59 100%);
$blueGradient: linear-gradient(225deg, #2051ff 0%, #2076ff 60%, #20afff 100%);
$greenGradient: linear-gradient(225deg, rgb(162, 206, 47) 0%, rgba(101, 201, 52, 1) 25%, rgba(32, 161, 89, 1) 100%);
$orangeGradient: linear-gradient(40deg, rgba(255, 197, 92, 1) 0%, rgba(255, 181, 46, 1) 25%, rgba(255, 165, 0, 1) 100%);

// shadows
$shadow: 2px 2px 8px rgba(17, 12, 26, 0.1);
//box-shadow: 0 4px 10px rgba(29, 19, 51, 0.1);

// animations
$linkAnimation: 200ms cubic-bezier(0.62, 0.02, 0.11, 0.955);

// ui
$error: $red500;
$errorBg: $red100;

$success: $green500;
$successBg: $green100;

$info: $blue300;
$infoBg: $blue100;

$warning: $yellow500;
$warningBg: $yellow300;

$borderColor: $gray200;
$disableBorderColor: $gray125;
$border: 1px solid $borderColor;

$backgroundGray: $gray150;
$backgroundDisabled: $gray25;
$hoverColor: $blue500;
$backgroundHoverColor: $gray50;
$disableColor: $gray700;

// links
$linkColor: $blue500;
$linkHoverColor: $blue400;

//text
$mainBlue: $blue500;
$mainBlueHover: #0077ff;
$textDarkGray: #4f5e76;
$textGray: #8492a6;
$errorColor: #f44336;
$lightBlue: #e2f5ff;
$disableText: #acafb4;

//Fonts
$baseFontFamily: "Poppins", sans-serif;
//$baseFontFamily: 'Ubuntu', sans-serif;

//statuses
$statusActiveColor: $green500;
$statusPendingColor: $yellow500;
$statusRejectColor: $red500;
$statusStopColor: $gray350;

//icons
$iconsGray: $gray300;
$iconsDarkGray: $gray600;

//BUTTONS
//lightBtn
$lightBtnText: #4f5e76;
$lightBtnIcon: $gray300;
$lightBtnIconHover: $blue500;
$lightBtnBgActive: #eaecf2;
$lightBtnBgDisabled: $gray25;
$lightBtnBorder: $border;
$lightBtnTextDisabled: #acafb4;

// toggle btns
$tbgBorder: $border;
$tbgDisableBorderColor: $disableBorderColor;
$tbgDisableColor: $disableColor;
$tgbBackgroundDisabled: $backgroundDisabled;
$tbBackgroundDisableSelected: $gray200;

//FIELDS
$labelColor: $textGray;
$labelDisableColor: $disableColor;

//input
$inputDisabledBorderColor: $disableBorderColor;
$inputBorder: $border;
$inputPlaceholderColor: $gray350;
$inputLabelColor: #acafb4;
$inputDisabledBorder: $border;
$inputDisabledBg: #efefef;
$inputDisabledColor: #b3b3b3;
$inputDisabledPlaceholderColor: #acafb4;
$inputDisabledLabelColor: $gray700;

//slide-toggle
$stBg: $gray300;
$stBgActive: $green500;
$stBgDisabled: $gray250;
$stFlagBgDisabled: #acafb4;

//radio-button
$rbBorderColor: $gray300;
$rbDisabledColor: $gray250;
$rbBorder: 1px solid $rbBorderColor;
$rbHoverBorder: 6px solid $rbBorderColor;
$rbPressedBorder: 6px solid $gray600;
$rbActiveBorder: 6px solid #2076ff;

//list
$liColor: $black;
$liHoverBgColor: $gray50;
$liHoverAddColor: #f44336;
$liHoverClearColor: #f44336;

//tooltip
$tooltipColor: $black;
$tooltipGrayColor: $gray50;
$tooltipLinkColor: #55c0ff;

//menu
$menuBg: $gray100;
$menuWidthClosed: 72px;
$menuWidthOpened: 240px;
$menuIconColor: $gray350;
$menuItemColor: $textDarkGray;
$menuItemBgColor: $menuBg;
$menuItemHoverBgColor: $grayBlue50;
$menuItemIconHoverBgColor: $grayBlue100;
$menuItemIconHoverColor: $hoverColor;
$menuAnimation: 200ms cubic-bezier(0.62, 0.02, 0.11, 0.955);
$tooltipAnimationBezier: 200ms cubic-bezier(0.425, 0.225, 0.22, 1.01);
