.adxad-toggle-button-group {
  &__content {
    border: $tbgBorder;
    padding: 4px;
    border-radius: 8px;
    height: 40px;
    display: inline-flex;
    align-items: center;
  }

  &.disabled {
    pointer-events: none;

    .adxad-toggle-button-group__content {
      border-color: $tbgDisableBorderColor;
      color: $tbgDisableColor;
      background: $tgbBackgroundDisabled;
    }

    .adxad-toggle-button {
      border-color: $disableBorderColor;
      color: $tbgDisableColor;
      background: $tgbBackgroundDisabled;

      &.selected {
        background: $tbBackgroundDisableSelected;
      }
    }
  }

  .label {
    color: #8492a6;
    font-size: 12px;
    transition: all 0.15s ease-in-out;
    margin-bottom: 8px;
    display: block;
    width: 100%;
  }
}

.adxad-toggle-button {
  padding: 0 12px;
  border-radius: 6px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  line-height: 1;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
  background: #fff;

  & + & {
    margin-left: 4px;
  }

  &.selected {
    background: #e2f5ff;
  }

  &:not(.selected):not(.disabled):hover {
    background: #eceff1;
  }

  &:focus {
    outline: none;
  }

  &.focus:not(.disabled) {
    background: #eceff1;

    &.selected {
      background: #d1efff;
    }
  }

  &.disabled {
    pointer-events: none;
    border-color: $disableBorderColor;
    color: $tbgDisableColor;
    background: $tgbBackgroundDisabled;
    cursor: default;

    &.selected {
      background: $tbBackgroundDisableSelected;
    }
  }
}
