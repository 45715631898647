.adxad-icon-btn {
  display: inline-flex;
  color: #667386;
  border: 1px solid transparent;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: #fff;

  &:hover {
    color: #2076ff;
  }

  &:not(:disabled):active,
  &:not(:disabled).active {
    color: #2076ff;
    background: #eaecf2;
  }

  &_stroked {
    border-color: #e3e6ed;
    color: #667386;
  }

  &.red {
    background: $redGradient;

    &:hover {
      background: #ff4d61;
    }
  }

  &:disabled {
    border: none;
    background: #eee;
    color: #bdbdbd;
    pointer-events: none;

    &:hover {
      color: #bdbdbd;
    }

    .material-icons {
      color: #bdbdbd;
    }
  }

  &:focus {
    outline: none;
  }
}
