.adxad-option {
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  display: block;

  &:hover {
    background-color: lightgrey;
  }
}

.adxad-options {
  position: absolute;
  z-index: 10000;

  &__wrapper {
    overflow-y: auto;
    max-height: 256px;
    position: relative;
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 8px 0;
    background: #fff;
    border-radius: 8px;
    box-shadow: $shadow;

    &.--no-scroll {
      max-height: none;
      overflow: auto;
    }
  }

  &:not(&_open-on-top) &__wrapper {
    top: 0;
  }

  &_open-on-top &__wrapper {
    bottom: 0;
  }

  &__option {
    font-family: $baseFontFamily;
    display: block;
    width: 100%;
    cursor: pointer;
    color: $black;
    font-size: 14px;
    padding: 8px 16px;
    word-break: break-all;

    &.hover,
    &:hover {
      background: #cfd8dc;
    }

    &.disabled {
      color: #b5b5b5;
      cursor: default;
    }
  }

  &__not-found {
    text-align: center;
    color: $errorColor;
    font-size: 16px;
    font-weight: 500;
    padding: 16px;
  }

  &__mask {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  &__lazy {
    margin: 16px;
    display: flex;
    justify-content: center;
  }

  &__filter-field {
    box-sizing: border-box;
    padding: 10px 16px;
    position: relative;

    .material-icons {
      color: #dadada;
    }
  }

  &__scroll-area {
    max-height: 196px;
    overflow-y: auto;
  }
}
