@import "assets/styles/vars";
@import "assets/styles/grid";
@import "app/ui/styles/ui";
@import "@angular/cdk/a11y-prebuilt.css";

.terms {
  &__link {
    font-weight: 500;
    color: $mainBlue;

    &:hover {
      text-decoration: none;
    }
  }
}

:root {
  --cropper-outline-color: rgba(238, 238, 238, 0.6);
  --grid-padding: 0;

  // Flat deal
  --showcase-grid-top-offset: 190px;
}
