.adxad-hint {
  align-items: center;
  box-sizing: border-box;
  color: #9199ab;
  display: flex;
  font-size: 11px;
  padding: 4px 0 0 16px;
  width: 100%;

  &.warning {
    color: $errorColor;
  }
}
