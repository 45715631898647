@import "media-query";

.adxad-tabs {
  display: block;
}

.adxad-tabs-nav {
  display: block;
  border-bottom: 1px solid #e3e6ed;
}

.adxad-tabs-body {
  padding-top: 16px;
}

.adxad-tab-label {
  font-family: $baseFontFamily;
  font-weight: 500;
  font-size: 14px;
  color: #a3adc1;
  margin-right: 24px;
  cursor: pointer;
  padding: 16px 0;
  position: relative;
  transition: color 0.15s ease-in-out;
  display: inline-flex;
  align-items: center;

  @include for-size(mobile) {
    padding: 10px 0;
    margin-right: 16px;
    font-size: 13px;
  }

  &:not(.disabled):hover {
    color: #4f5e76;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 0;
    height: 2px;
    background: linear-gradient(190.01deg, #ff3d6c 0%, #ff5a59 59.37%, #ff8b59 100%);
    transition: width 0.2s ease-in-out;
  }

  &.active {
    color: #4f5e76;

    &:focus {
      outline: none;
    }

    &:after {
      width: 100%;
    }
  }

  &.disabled {
    cursor: default;
    color: #d7dbe5;
    pointer-events: none;
  }
}

.adxad-tab-label-status-icon {
  margin-left: 8px;
  width: 20px;
  height: 20px;
  position: relative;

  &:not(.success):not(.invalid):after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: block;
    background: #b5bdcd;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  &.invalid {
    color: $errorColor;
  }

  &.success {
    color: $success;
  }

  .material-icons {
    width: 16px;
    height: 16px;
    font-size: 16px;
  }
}
