@mixin minW($width) {
  @media screen and (min-width: $width + "px") {
    @content;
  }
}

@mixin maxW($width) {
  @media screen and (max-width: $width + "px") {
    @content;
  }
}

@mixin media($media, $value) {
  @media screen and ($media: $value) {
    @content;
  }
}
