@import "media-query";

.adxad-stroked-btn {
  font-family: $baseFontFamily;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  border: 1px solid #e3e6ed;
  border-radius: 8px;
  display: inline-block;
  white-space: nowrap;
  color: #4f5e76;
  cursor: pointer;
  background: #fff;
  padding: 0 20px;
  height: 40px;
  transition: background-color 0.15s ease-out;

  @include for-size(mobile) {
    padding: 0 16px;
    font-size: 13px;
  }

  &_icon {
    padding-left: 42px;
    position: relative;

    .material-icons {
      color: #b5bdcd;
      position: absolute;
      left: 12px;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 24px;

      &.icon-color {
        &-error {
          color: #f44336;
        }

        &-success {
          color: #2dca73;
        }
      }
    }
  }

  &:hover {
    background-color: #f6f7f9;

    .material-icons {
      color: #2076ff;

      &.icon-hover-color {
        &-error {
          color: $error;
        }

        &-success {
          color: $success;
        }

        &-warning {
          color: $warning;
        }
      }
    }
  }

  &:active {
    background: #e3e6ed;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    border: 1px solid #eee;
    background: #eee;
    color: #acafb4;
    pointer-events: none;

    .material-icons,
    &:hover .material-icons {
      color: #acafb4;
    }
  }
}
