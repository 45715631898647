@import "../mixins/placeholder";

.adxad-combobox {
  display: block;
  width: 100%;

  &__wrapper {
    position: relative;
  }
}
