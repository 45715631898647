@import "../mixins/placeholder";

.adxad-textarea {
  display: block;
  position: relative;

  &__input {
    background: #fff;
    border: 1px solid #e3e6ed;
    border-radius: 8px;
    scroll-padding: 11px;
    padding: 11px 16px;
    font-size: 14px;
    color: $black;
    font-weight: 400;
    transition: all 0.15s ease-in-out;
    width: 100%;
    min-height: 160px;
    outline: none;
    font-family: $baseFontFamily;

    &.fix-width {
      resize: vertical;
    }

    &.no-resize {
      resize: none;
    }

    &:disabled {
      border-color: #e0e0e0;
      color: #b3b3b3;
      background: #efefef;
    }

    @include inputPlaceholder() {
      color: #a3adc1;
      font-size: 13px;
      font-family: $baseFontFamily;
      font-weight: 400;
    }
  }

  &.ng-invalid.ng-touched &__input {
    border-color: $errorColor;

    @include inputPlaceholder() {
      color: $errorColor;
    }
  }

  &__label {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;

    .label-content {
      color: #8492a6;
      font-size: 12px;
      transition: all 0.15s ease-in-out;
      margin-bottom: 8px;
      display: block;
    }

    .disabled & {
      .label-content {
        color: #9e9e9e;
      }
    }
  }

  &.ng-invalid.ng-touched &__label {
    .label-content {
      color: $errorColor;
    }
  }
}
