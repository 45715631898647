@import "../mixins/placeholder";

.adxad-number-input {
  min-width: 108px;
  display: block;
  position: relative;

  &__input {
    background: #fff;
    border: $inputBorder;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 11px 70px 11px 16px;
    font-size: 14px;
    color: $black;
    font-weight: 500;
    line-height: 1;
    transition: all 0.15s ease-in-out;
    width: 100%;
    height: 40px;
    font-family: $baseFontFamily;

    &:disabled {
      border-color: $inputDisabledBorderColor;
      color: $inputDisabledColor;
      background: $inputDisabledBg;

      @include inputPlaceholder() {
        color: $inputDisabledPlaceholderColor;
      }
    }

    @include inputPlaceholder() {
      color: $inputPlaceholderColor;
      font-size: 13px;
      font-family: $baseFontFamily;
      font-weight: 400;
    }

    &:focus {
      outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }
  }

  &.ng-invalid.ng-touched &__input {
    border-color: $errorColor;

    @include inputPlaceholder() {
      color: $errorColor;
    }
  }

  &__label {
    display: flex;
    flex-direction: column;
    width: 100%;

    .label-content {
      color: #8492a6;
      font-size: 12px;
      transition: all 0.15s ease-in-out;
      margin-bottom: 8px;
      display: block;
    }
  }

  &.ng-invalid.ng-touched &__label {
    .label-content {
      color: $errorColor;
    }
  }

  &:not(.disabled):hover &__label {
    .label-content {
      color: #2076ff;
    }
  }

  &.disabled {
    .label-content {
      color: $inputDisabledLabelColor;
    }

    .adxad-prefix,
    .adxad-suffix {
      color: $inputDisabledPlaceholderColor;
    }
  }

  //suffix && prefix
  .adxad-suffix,
  .adxad-prefix {
    position: absolute;
    bottom: 8px;
    z-index: 2;
  }

  &.suffix &__input {
    padding-right: 100px;
  }

  &.prefix &__input {
    padding-left: 42px;
  }

  .adxad-suffix {
    right: 70px;
  }

  .adxad-prefix {
    left: 10px;
  }

  // btns
  &__btns {
    position: absolute;
    z-index: 10;
    right: 1px;
    bottom: 1px;
    background: #fff;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    border-left: $inputBorder;
    height: 38px;
    width: 60px;
    padding: 0 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &.disabled &__btns {
    background: none;
  }

  &__minus-btn,
  &__plus-btn {
    color: $blue600;
    cursor: pointer;
    transition: color 0.15s ease-out;

    &:hover {
      color: $hoverColor;
    }

    &.material-icons {
      font-size: 20px;
      width: 20px;
      height: 20px;
    }
  }

  &.disabled &__minus-btn,
  &.disabled &__plus-btn {
    cursor: default;
    color: $inputDisabledPlaceholderColor;
  }

  // Material design
  &.material &__container {
    border: none;
    border-bottom: 1px solid #cfd8dc;
    border-radius: 0;
    padding: 0;

    .adxad-number-input__input {
      height: 40px;
    }
  }

  &.material.prefix &__container {
    padding-left: 32px;
  }

  &.material.suffix &__container {
    padding-right: 32px;
  }

  &.material.suffix .adxad-suffix {
    right: 0;
  }

  &.material.prefix .adxad-prefix {
    left: 0;
  }

  &.material.suffix .adxad-suffix {
    right: 0;
  }
}
