@import "tabs";
@import "sidebar-modal";
@import "buttons";
@import "fields/fields";
@import "alerts";
@import "modal";
@import "paginator";
@import "loader";
@import "reload-token";
@import "chips";
@import "tooltip";
@import "media-query";

html {
  scroll-behavior: smooth;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

*:focus {
  outline: none;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  position: relative;
  color: $black;
  font-family: $baseFontFamily;

  &.hidden {
    overflow: hidden;
  }
}

a,
.link {
  text-decoration: none;
  color: $linkColor;
  cursor: pointer;
  transition: color $linkAnimation;

  &:hover {
    outline: none;
    color: $linkHoverColor;
  }
}

/** Scroll */
::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #dbdee5;
  border-radius: 24px;

  &:hover {
    background: #b5bdcd;
  }

  &:active {
    background: #667386;
  }
}

/** End Scroll */

.pointer {
  cursor: pointer;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.w100 {
  width: 100%;
}

.adxad-toolbar {
  align-items: center;

  &.hide {
    display: none;
  }
}

.adxad-main-container {
  padding-right: 16px;
}

.adxad-grid-header {
  position: relative;
  padding-bottom: 24px;

  @include for-size(mobile) {
    padding-bottom: 0;
  }

  &__add-btn {
    position: absolute;
    bottom: 33px;
    right: 0;
    z-index: 10;
    text-transform: uppercase;

    @include for-size(mobile) {
      font-size: 0;
      padding-right: 0;
      bottom: 24px;

      &.adxad-btn_icon {
        .material-icons {
          left: 8px;
        }
      }
    }
  }
}

.adxad-grid {
  border: 1px solid #e3e6ed;
  border-radius: 8px;
  overflow-x: auto;
  position: sticky;
  top: $headerHeight;

  .checked {
    background: #f9f9fb;
  }

  &.hide {
    display: none;
  }
}

.adxad-header-title {
  font-size: 32px;
  font-weight: 700;
  margin: 0 0 16px;
}

.adxad-actions {
  align-items: center;
  display: flex;
}

//.adxad-add-btn {
//  width: 48px;
//  height: 48px;
//  border-radius: 50px;
//  background: linear-gradient(225deg, #ff3d6c 0%, #ff5a59 59.37%, #ff8b59 100%);
//  color: #fff;
//  border: none;
//  padding: 12px;
//  z-index: 2;
//  cursor: pointer;
//
//  &:active,
//  &:focus {
//    outline: none;
//  }
//}

.adxad-section-title {
  font-weight: 700;
  color: $black;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 0 16px;

  &:after {
    content: "";
    display: block;
    border-bottom: 1px solid #e3e6ed;
    flex-grow: 1;
  }
}

.field-group {
  margin-bottom: $colMargin * 2;
}

.adxad-stepper {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 1px;
    background: #e3e6ed;
    z-index: -1;
  }

  &__item {
    color: #a3adc1;
    font-weight: 500;
    padding: 8px 18px;
    background: #fff;
    border: 1px solid #e3e6ed;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &-icon {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background: #b5bdcd;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 13px;
      margin-right: 8px;

      &.active {
        background: #2076ff;
      }

      &.invalid {
        background: #fff;
        color: $errorColor;
      }

      &.success {
        background: #fff;
        color: #b5bdcd;
      }
    }

    &:disabled {
      cursor: default;
      background: #dbdee5;
      color: #acafb4;
      border: 1px solid #dbdee5;
    }

    &:focus {
      outline: none;
    }

    &.active {
      color: #4f5e76;
    }

    &:disabled &-icon {
      color: #dbdee5;
    }
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.modal-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-with-loader {
  position: relative;

  &__loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__loader + &__content {
    opacity: 0;
  }
}
