@import "vars";

adxad-inline-edit {
  display: flex;
  flex-direction: column;
  position: relative;

  input {
    background: #fff;
    border: $inputBorder;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0 64px 0 11px;
    font-size: 16px;
    color: $black;
    font-weight: 500;
    line-height: 1;
    transition: all 0.15s ease-in-out;
    width: 100%;
    height: 40px;
    outline: none;
    font-family: $baseFontFamily;

    &:focus {
      border-bottom-color: $rbActiveBorder;
    }

    &.invalid {
      border-color: $errorColor;
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
    opacity: 1;
    -webkit-appearance: none;
  }

  .value {
    cursor: pointer;

    &:hover {
      color: $mainBlue;
    }
  }

  .adxad-hint {
    font-weight: normal;
  }

  .actions {
    right: 8px;
    position: absolute;
    top: 8px;

    .--apply {
      cursor: pointer;
      color: $success;
    }

    .--exit {
      cursor: pointer;
      color: $errorColor;
    }
  }

  .error-msg {
    display: flex;
    flex-direction: column;
    gap: 4px 0;
    margin: 4px 0 0 12px;

    p {
      margin: 0;
      padding: 0;
      color: $errorColor;
      font-size: 11px;
      font-weight: 400;
    }
  }

  .adxad-hint {
    padding-left: 12px;
  }
}
