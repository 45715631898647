.adxad-checkbox {
  display: flex;
  cursor: pointer;
  align-items: center;

  &__icon {
    border: 1px solid #b5bdcd;
    width: 18px;
    height: 18px;
    min-width: 18px;
    background: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #fff;

    .material-icons {
      font-size: 14px;
      position: relative;
      z-index: 1;
    }

    &_checked {
      border-color: #2076ff;
    }
  }

  &:not(.disabled):hover &__icon {
    border-color: #2076ff;
  }

  &__icon-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 0;
    height: 0;
    background-color: #2076ff;
    transition: all 0.12s ease;
    z-index: 0;

    &.open {
      width: 18px;
      height: 18px;
      border-radius: 4px;
    }
  }

  &__text {
    margin-left: 11px;
    font-size: 13px;
    font-weight: 400;
    color: #a3adc1;
  }

  &.disabled {
    cursor: default;
  }

  &.disabled &__icon {
    border-color: #dbdee5;
  }

  &.disabled &__text {
    color: #dbdee5;
  }

  &.disabled &__icon-bg {
    background-color: #dbdee5;
  }
}
