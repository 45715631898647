@import "../mixins/placeholder";

.adxad-autocomplete {
  display: block;

  &__icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    color: #dadada;
  }

  &__label {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;

    .label-content {
      color: #8492a6;
      font-size: 12px;
      transition: all 0.15s ease-in-out;
      margin-bottom: 8px;
      display: block;
    }
  }

  &:not(.disabled):hover &__label {
    .label-content {
      color: #2076ff;
    }
  }

  &.disabled &__label {
    color: #9e9e9e;
  }

  &.ng-invalid.ng-touched &__label {
    color: $errorColor;

    .adxad-autocomplete__icon,
    .label-content {
      color: $errorColor;
    }
  }

  &__input {
    background: #fff;
    border: $inputBorder;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 10px 16px 10px 38px;
    font-size: 16px;
    color: $black;
    font-weight: 500;
    line-height: 1;
    width: 100%;
    height: 40px;
    outline: none;
    font-family: $baseFontFamily;

    @include inputPlaceholder() {
      color: $inputPlaceholderColor;
      font-size: 13px;
      font-family: $baseFontFamily;
      font-weight: 400;
    }

    &:disabled {
      border-color: #e0e0e0;
      color: #a0a0a0;
      background: #f4f4f4;

      @include inputPlaceholder() {
        color: $inputDisabledPlaceholderColor;
      }
    }

    &:focus {
      outline: none;
    }
  }

  &.ng-invalid.ng-touched &__input {
    border-color: $errorColor;

    @include inputPlaceholder() {
      color: $errorColor;
    }
  }
}
