@mixin for-size($size) {
  @if $size == mobile {
    @media (max-width: 599px) and (min-width: 1px) {
      @content;
    }
  } @else if $size == tablet {
    @media (max-width: 899px) and (min-width: 600px) {
      @content;
    }
  } @else if $size == laptop {
    @media (max-width: 1199px) and (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop {
    @media (min-width: 1200px) {
      @content;
    }
  }
}
