.adxad-radio-button {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  min-height: 18px;

  &.vertical {
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: center;
    color: #474f5c;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
    margin-right: 16px;
    position: relative;
    padding-left: 24px;
  }

  &__circle {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 18px;
    height: 18px;
    border-radius: 18px;
    border: $rbBorder;
    background: #fff;
    transition: border 0.15s;
  }

  &:not(.disabled) &__item:hover &__circle {
    border: $rbHoverBorder;
  }

  &:not(.disabled) &__item:active &__circle {
    border: $rbPressedBorder;
  }

  &:not(.disabled) &__item.active &__circle,
  &:not(.disabled) &__item.active:hover &__circle {
    border: $rbActiveBorder;
  }

  &.disabled &__item {
    cursor: default;
    color: $rbDisabledColor;
  }

  &.disabled &__item.active &__circle {
    cursor: default;
    color: $rbDisabledColor;
    border-width: 6px;
  }

  &.disabled &__circle {
    border-color: $rbDisabledColor;
  }
}
