@import "vars";

.file-input {
  display: none;
}

.file-name {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  color: $inputPlaceholderColor;
  font-size: 13px;
  font-family: $baseFontFamily;
  font-weight: 400;
  border-right: $inputBorder;
  min-height: 38px;
  box-sizing: border-box;
  padding: 0 16px;

  &.is-file-name {
    color: $black;
  }
}

.file-upload-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  font-family: $baseFontFamily;
  outline: none;
  border: none;
  height: 38px;
  box-sizing: border-box;
  padding: 0 12px 0 8px;
  gap: 0 6px;
  font-weight: 500;
  cursor: pointer;
  color: $blue600;
  transition: color 0.15s ease-out;
  line-height: normal;

  .icon {
    font-size: 20px;
  }

  &:hover {
    color: $hoverColor;
  }
}

.adxad-file-input {
  &__label {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;

    .label-content {
      color: #8492a6;
      font-size: 12px;
      transition: all 0.15s ease-in-out;
      margin-bottom: 8px;
      display: block;
    }
  }

  &__input {
    align-items: center;
    display: flex;
    background: #fff;
    border: $inputBorder;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0;
    font-size: 14px;
    color: $black;
    font-weight: 400;
    line-height: 1;
    transition: all 0.15s ease-in-out;
    width: 100%;
    height: 40px;
    outline: none;
    font-family: $baseFontFamily;

    &:disabled {
      border-color: $inputDisabledBorderColor;
      color: $inputDisabledColor;
      background: $inputDisabledBg;
    }
  }

  &:not(.disabled):hover &__label {
    .label-content {
      color: #2076ff;
    }
  }

  &.disabled {
    .label-content {
      color: $inputDisabledLabelColor;
    }

    .adxad-prefix,
    .adxad-suffix {
      color: $inputDisabledPlaceholderColor;
    }
  }

  &.ng-invalid.ng-touched &__label {
    .file-name,
    .label-content {
      color: $errorColor;
    }

    .adxad-file-input__input {
      border-color: $errorColor;
    }
  }
}
