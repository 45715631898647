@import "../mixins/placeholder";

.adxad-select {
  display: block;
  font-family: $baseFontFamily;

  &__input {
    background: #fff;
    border: $inputBorder;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 10px 38px 10px 16px;
    font-size: 14px;
    color: $black;
    line-height: 1;
    transition: all 0.15s ease-in-out;
    width: 100%;
    height: 40px;
    outline: none;
    cursor: pointer;
    font-family: $baseFontFamily;

    .fields-group & {
      border: none;
      border-radius: 0;
    }

    @include inputPlaceholder() {
      color: $inputPlaceholderColor;
      font-size: 13px;
      font-family: $baseFontFamily;
    }

    &:disabled {
      border-color: #e0e0e0;
      color: #a0a0a0;
      background: #f4f4f4;
      pointer-events: none;
    }

    &:focus {
      outline: none;
    }
  }

  &__input-mask {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &.ng-invalid.ng-touched &__input {
    border-color: $errorColor;

    @include inputPlaceholder() {
      color: $errorColor;
    }
  }

  &__field {
    cursor: pointer;
    height: 40px;
    width: 100%;
    transition: all 0.15s ease-in-out;
    position: relative;

    &.is-filterable {
      .adxad-select__input {
        padding-left: 38px;
      }
    }
  }

  &.disabled &__field {
    cursor: default;
  }

  &.ng-invalid.ng-touched &__field {
    &.is-filterable {
      .adxad-select__search-icon {
        color: $errorColor;
      }
    }
  }

  &__label {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;

    .label-content {
      color: #8492a6;
      font-size: 12px;
      transition: all 0.15s ease-in-out;
      margin-bottom: 8px;
      display: block;
    }
  }

  &:not(.disabled):hover &__label {
    .label-content {
      color: #2076ff;
    }
  }

  &.disabled &__label {
    .label-content {
      color: #9e9e9e;
    }
  }

  &.ng-invalid.ng-touched &__label {
    .label-content {
      color: $errorColor;
    }
  }

  &__arrow {
    position: absolute;
    right: 19px;
    top: 0;
    bottom: 0;
    margin: auto;

    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $blue600;
  }

  &:not(.disabled):hover &__arrow {
    border-top-color: #2076ff;
  }

  &.disabled &__arrow {
    border-top-color: #9e9e9e;
  }

  &.ng-invalid.ng-touched &__arrow {
    border-top-color: $errorColor;
  }

  &__search-icon {
    position: absolute;
    top: 8px;
    bottom: 0;
    left: 8px;
    color: #dadada;
  }
}
